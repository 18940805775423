import React from "react";
import Icon from "../../images/Image";
import "./Person.css";

const Person = ({
  colorShoes,
  colorPants,
  colorShirt,
  underwearColor,
  colorSocks,
  eyesColor,
  colorBanana,
  isSleeping,
  colorCereal,
  colorBrush,
  isTidyUp,
}) => {
  const handleClick = (e) => {
    console.log(e.target.value);
  };

  return (
    // <div className="box">
      <Icon
        className="image-man"
        eyesColor={eyesColor}
        underwearColor={underwearColor}
        colorPants={colorPants}
        colorShirt={colorShirt}
        onClick={handleClick}
        colorSocks={colorSocks}
        colorShoes={colorShoes}
        colorBanana={colorBanana}
        isSleeping={isSleeping}
        colorCereal={colorCereal}
        colorBrush={colorBrush}
        isTidyUp={isTidyUp}
      ></Icon>
      
  );
};

export default Person;
