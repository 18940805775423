import React, { useEffect } from "react";
import "./CenterPanel.css";
import Person from "../person/Person";

const CenterPanel = ({
  eyesColor,
  colorPants,
  colorShirt,
  underwearColor,
  colorSocks,
  colorShoes,
  colorBanana,
  isSleeping,
  colorCereal,
  colorBrush,
  isTidyUp,
  input,
  setInput,
  newValues,
  setNewValues,
  setCurrentAction,
}) => {


  const handleChange = (e) => {
    // e.preventDefault();
    console.log(e.target.value);
    setInput(e.target.value);
  };
  const getBtnId = (val) => {
    const splitted = val.split(" ");
    let btnId = "btn-";
    let i;
    if (splitted.length > 1) {
      for (i = 0; i < splitted.length - 1; i++) {
        btnId += splitted[i] + "-";
      }
      return (btnId += splitted[i]);
    } else {
      return (btnId += val);
    }
  };
  const mapNewValues = () => {
    return newValues?.map((val, i) => {
      const btnId = getBtnId(val);

      return (
        <button
          onClick={(e) => {
            setCurrentAction(val);
          }}
          id={btnId}
          key={i}
        >
          {val}
        </button>
      );
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedArray = [...newValues, input];
    setNewValues(updatedArray);

    setInput("");
  };

  useEffect(() => {}, [newValues]);
  return (
    <main className="center-panel">
      <Person
        colorBrush={colorBrush}
        colorCereal={colorCereal}
        eyesColor={eyesColor}
        colorShoes={colorShoes}
        colorSocks={colorSocks}
        underwearColor={underwearColor}
        colorShirt={colorShirt}
        colorPants={colorPants}
        colorBanana={colorBanana}
        isSleeping={isSleeping}
        isTidyUp={isTidyUp}
      ></Person>
      <div className="new-values-box">
        <form onSubmit={handleSubmit}>
          <p>New Actions:</p>
          <input
            id="input"
            type="text"
            value={input}
            onChange={(e) => handleChange(e)}
          ></input>
          <button id="btn-add">Add</button>
        </form>
        <ul className="new-values-list">{newValues && mapNewValues()}</ul>
      </div>
    </main>
  );
};

export default CenterPanel;
