import React from "react";
import "./SVG.css";
function Icon({
  eyesColor,
  colorShoes,
  colorPants,
  colorShirt,
  underwearColor,
  colorSocks,
  colorBanana,
  isSleeping,
  colorCereal,
  colorBrush,
  isTidyUp,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg5"
      width="210mm"
      height="600px"
      version="1.1"
      viewBox="0 0 793.701 1122.52"
    >
      <path
        id="banana"
        fill={colorBanana}
        fillOpacity="1"
        // stroke="#000"
        strokeWidth="1.002"
        d="M148.756 156.193h0c70.612 0-59.66 68.799-59.66 147.224 0 78.425 130.272 135.9 59.66 135.9h0c-70.612 0-127.458-63.137-127.458-141.562 0-78.426 56.846-141.562 127.458-141.562z"
        opacity="1"
        paintOrder="fill markers stroke"
        display={colorBanana === "none" ? "none" : "block"}
      ></path>

      <g
        id="cereal"
        stroke="#000"
        paintOrder="fill markers stroke"
        display={colorCereal === "none" ? "none" : "block"}
      >
        <g id="cereal-bowl">
          <rect
            id="rect19252"
            width="48.464"
            height="10.438"
            x="82.016"
            y="193.856"
            fill={colorCereal}
            fillOpacity="1"
            strokeWidth="0.247"
            rx="0"
            ry="10.438"
          ></rect>
          <path
            id="path19198"
            fill="#0080a7"
            fillOpacity="1"
            strokeWidth="0.247"
            d="M165.523 149.866a59.648 49.955 0 01-29.824 43.262 59.648 49.955 0 01-59.648 0 59.648 49.955 0 01-29.824-43.262"
          ></path>
          <path
            id="path19261"
            fill="#fff"
            strokeWidth="0.178"
            d="M165.666 149.412c-.096 4.591-26.79 11.392-59.539 11.188-32.748-.204-59.903-6.934-59.807-11.525"
          ></path>
          <path
            id="path19261-1"
            fill="#008090"
            fillOpacity="1"
            strokeWidth="0.115"
            d="M46.207 150.39c.074-1.916 26.746-4.806 59.507-4.787 32.76.02 59.958 2.773 59.884 4.689"
          ></path>
        </g>

        <g id="cereal-balls" fill="#a40" strokeWidth="0.058">
          <ellipse
            id="path19379"
            cx="68.968"
            cy="153.594"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-4"
            cx="85.744"
            cy="155.83"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-93"
            cx="135.699"
            cy="155.085"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-40"
            cx="144.646"
            cy="152.848"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-7"
            cx="104.384"
            cy="157.322"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-57"
            cx="79.034"
            cy="152.848"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-50"
            cx="115.568"
            cy="158.067"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-6"
            cx="111.094"
            cy="152.848"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-47"
            cx="93.2"
            cy="156.576"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-8"
            cx="104.384"
            cy="152.102"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-400"
            cx="109.603"
            cy="147.629"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-62"
            cx="90.218"
            cy="149.12"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-1"
            cx="118.55"
            cy="150.611"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-85"
            cx="83.507"
            cy="147.629"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-89"
            cx="99.91"
            cy="147.629"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-99"
            cx="99.91"
            cy="155.83"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-3"
            cx="130.48"
            cy="157.322"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-37"
            cx="59.648"
            cy="152.848"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-73"
            cx="76.051"
            cy="155.085"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-2"
            cx="73.814"
            cy="149.866"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-56"
            cx="140.918"
            cy="151.357"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-34"
            cx="120.787"
            cy="158.067"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-0"
            cx="143.155"
            cy="154.339"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-60"
            cx="126.006"
            cy="150.611"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-474"
            cx="127.498"
            cy="151.357"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-90"
            cx="124.515"
            cy="155.085"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-5"
            cx="112.586"
            cy="154.339"
            rx="4.195"
            ry="3.822"
          ></ellipse>
          <ellipse
            id="path19379-9"
            cx="96.182"
            cy="152.102"
            rx="4.195"
            ry="3.822"
          ></ellipse>
        </g>
      </g>

      <defs id="defs2">
        <path
          id="rect1540"
          d="M-2031 518.452H-1460.2269999999999V889.454H-2031z"
        ></path>
      </defs>

      <g id="layer1" transform="translate(856.572 -10.96)">
        <g id="g5724" transform="matrix(.95198 0 0 .91184 -825.215 44.088)">
          <g
            id="g9400"
            fill="#fca"
            fillOpacity="1"
            strokeWidth="0.97"
            transform="matrix(.93507 .35446 -.46222 .88677 0 0)"
          >
            <rect
              id="rect8738"
              width="96.934"
              height="162.589"
              x="703.572"
              y="821.272"
              opacity="1"
              ry="81.294"
            ></rect>
            <rect
              id="rect8738-6"
              width="96.934"
              height="162.589"
              x="859.387"
              y="755.181"
              ry="81.294"
            ></rect>
          </g>
          <path
            id="rect4679"
            fill="#fca"
            strokeWidth="0.796"
            d="M520.078 1041.1c-140.063 15.443-32.838-.728-134.218-369.724-98.543 372.822-13.101 383.05-130.39 369.13l28.572-545.943 224.024-24.276c11.827 186.662.184 384.151 12.012 570.814z"
          ></path>
          <path
            id="underwear-pants"
            fill={underwearColor}
            fillOpacity="0.997"
            strokeWidth="0.697"
            d="M530.98 768.388C394.882 812.93 418.742 884.35 389.735 686.86c9.547 256.78-37.756 43.035-151.977 133.4 33.2-319.853 37.682-232.825 70.579-341.681l187.758 2.483C600.587 869.8 530.98 768.388 530.98 768.388z"
          ></path>

          {/* SOCK START */}

          <g id="socks-container">
            <g
              id="socks"
              opacity={colorSocks}
              transform="matrix(1.05045 0 0 1.09668 866.845 -48.35)"
            >
              <g
                id="g9511"
                transform="matrix(.96396 0 0 1.07633 840.7 226.414)"
              >
                <rect
                  id="rect8738-6-0"
                  width="105.886"
                  height="154.261"
                  x="-903.119"
                  y="1099.333"
                  fill="#ececec"
                  strokeWidth="0.988"
                  ry="77.13"
                  transform="matrix(.95666 .29121 -.54445 .8388 0 0)"
                ></rect>
                <rect
                  id="rect9427"
                  width="85.933"
                  height="128.408"
                  x="-1473.909"
                  y="582"
                  fill="#ececec"
                  strokeWidth="0.843"
                  opacity="1"
                  rx="0"
                  ry="0"
                ></rect>
                <path
                  id="rect9431"
                  fill="navy"
                  d="M-1478.256 596.64H-1387.977V606.4H-1478.256z"
                  opacity="1"
                ></path>
                <path
                  id="rect9431-7"
                  fill="navy"
                  d="M-1474.596 616.16H-1384.317V625.92H-1474.596z"
                ></path>
              </g>
              <g
                id="g9511-1"
                transform="matrix(.97943 -.07066 .07588 1.05179 978.256 131.442)"
              >
                <rect
                  id="rect8738-6-0-1"
                  width="105.886"
                  height="154.261"
                  x="-903.119"
                  y="1099.333"
                  fill="#ececec"
                  strokeWidth="0.988"
                  ry="77.13"
                  transform="matrix(.95666 .29121 -.54445 .8388 0 0)"
                ></rect>
                <rect
                  id="rect9427-8"
                  width="85.933"
                  height="128.408"
                  x="-1473.909"
                  y="582"
                  fill="#ececec"
                  strokeWidth="0.843"
                  opacity="1"
                  rx="0"
                  ry="0"
                ></rect>
                <path
                  id="rect9431-1"
                  fill="navy"
                  d="M-1478.256 596.64H-1387.977V606.4H-1478.256z"
                  opacity="1"
                ></path>

                <path
                  id="rect9431-7-1"
                  fill="navy"
                  d="M-1474.596 616.16H-1384.317V625.92H-1474.596z"
                ></path>
              </g>
            </g>
            {/* </path> */}
            {/* SOCK End */}
          </g>

          {/* SOES START */}

          <g id="shoes-container">
            <g
              id="shoes"
              fill="#280b0b"
              opacity={colorShoes}
              transform="matrix(1.04928 0 0 1.02929 -27.923 -36.986)"
            >
              <g
                id="g9511-3"
                transform="matrix(1.01259 0 0 1.1804 1755.12 196.573)"
              >
                <rect
                  id="rect8738-6-0-4"
                  width="105.886"
                  height="154.261"
                  x="-903.119"
                  y="1099.333"
                  fillOpacity="1"
                  strokeWidth="0.988"
                  ry="77.13"
                  transform="matrix(.95666 .29121 -.54445 .8388 0 0)"
                ></rect>
                <rect
                  id="rect9427-3"
                  width="85.933"
                  height="58.133"
                  x="-1473.909"
                  y="652.276"
                  fillOpacity="1"
                  strokeWidth="0.567"
                  opacity="1"
                  rx="0"
                  ry="0"
                ></rect>
                <g
                  id="g632-5"
                  transform="matrix(.78303 .38764 -.52928 .85755 -524.516 863.118)"
                >
                  <path
                    id="rect424-3"
                    fill="#f9f9f9"
                    strokeWidth="0.569"
                    d="M-939.924 512.198H-933.039V579.841H-939.924z"
                    transform="matrix(.96836 .24955 -.16126 .98691 0 0)"
                  ></path>
                  <path
                    id="rect424-9-5"
                    fill="#f9f9f9"
                    strokeWidth="0.575"
                    d="M-1032.859 -52.715H-1025.8V14.439999999999998H-1032.859z"
                    transform="matrix(.95077 -.30991 .21179 .97732 0 0)"
                  ></path>
                  <ellipse
                    id="path290-4"
                    cx="-994.294"
                    cy="267.535"
                    fill="#f9f9f9"
                    strokeWidth="1"
                    rx="13.42"
                    ry="15.86"
                  ></ellipse>
                  <g id="g652-3">
                    <ellipse
                      id="path290-3-6"
                      cx="-985.754"
                      cy="265.958"
                      fill="#f9f9f9"
                      strokeWidth="1"
                      rx="13.42"
                      ry="15.86"
                    ></ellipse>
                  </g>
                  <ellipse
                    id="path450-3-5"
                    cx="-982.094"
                    cy="267.178"
                    fill="#280b0b"
                    strokeWidth="0.816"
                    rx="4.88"
                    ry="6.1"
                  ></ellipse>
                  <ellipse
                    id="path450-3-9-9"
                    cx="-995.514"
                    cy="268.398"
                    fill="#280b0b"
                    rx="6.1"
                    ry="7.32"
                  ></ellipse>
                  <g
                    id="g645-5"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.224"
                    transform="matrix(1.01478 -.45871 .56093 .82985 -949.473 -423.558)"
                  >
                    <rect
                      id="rect10241-0-4"
                      width="61"
                      height="4.88"
                      x="-433.097"
                      y="673.436"
                      ry="0"
                    ></rect>
                    <rect
                      id="rect10241-7-9"
                      width="61"
                      height="4.88"
                      x="-428.217"
                      y="658.796"
                      ry="0"
                    ></rect>
                    <rect
                      id="rect10241-79-1"
                      width="61"
                      height="4.88"
                      x="-418.457"
                      y="644.156"
                      ry="0"
                    ></rect>
                  </g>
                </g>
              </g>
              <g
                id="g9511-1-1"
                transform="matrix(1.02884 -.0775 .0797 1.15348 1899.616 92.419)"
              >
                <rect
                  id="rect8738-6-0-1-2"
                  width="105.886"
                  height="154.261"
                  x="-903.119"
                  y="1099.333"
                  fill="#280b0b"
                  fillOpacity="1"
                  strokeWidth="0.988"
                  ry="77.13"
                  transform="matrix(.95666 .29121 -.54445 .8388 0 0)"
                ></rect>
                <rect
                  id="rect9427-8-6"
                  width="85.734"
                  height="51.459"
                  x="-1565.873"
                  y="727.716"
                  fill="#280b0b"
                  fillOpacity="1"
                  strokeWidth="0.533"
                  opacity="1"
                  rx="0"
                  ry="0"
                  transform="matrix(.99918 .04053 .11638 .9932 0 0)"
                ></rect>
                <g
                  id="g632"
                  transform="matrix(.7361 .44614 -.58586 .8382 -554.222 923.88)"
                >
                  <path
                    id="rect424"
                    fill="#f9f9f9"
                    strokeWidth="0.569"
                    d="M-939.924 512.198H-933.039V579.841H-939.924z"
                    transform="matrix(.96836 .24955 -.16126 .98691 0 0)"
                  ></path>
                  <path
                    id="rect424-9"
                    fill="#f9f9f9"
                    strokeWidth="0.575"
                    d="M-1032.859 -52.715H-1025.8V14.439999999999998H-1032.859z"
                    transform="matrix(.95077 -.30991 .21179 .97732 0 0)"
                  ></path>
                  <ellipse
                    id="path290"
                    cx="-994.294"
                    cy="267.535"
                    fill="#f9f9f9"
                    strokeWidth="1"
                    rx="13.42"
                    ry="15.86"
                  ></ellipse>
                  <g id="g652">
                    <ellipse
                      id="path290-3"
                      cx="-985.754"
                      cy="265.958"
                      fill="#f9f9f9"
                      strokeWidth="1"
                      rx="13.42"
                      ry="15.86"
                    ></ellipse>
                  </g>
                  <ellipse
                    id="path450-3"
                    cx="-982.094"
                    cy="267.178"
                    fill="#280b0b"
                    strokeWidth="0.816"
                    rx="4.88"
                    ry="6.1"
                  ></ellipse>
                  <ellipse
                    id="path450-3-9"
                    cx="-995.514"
                    cy="268.398"
                    fill="#280b0b"
                    rx="6.1"
                    ry="7.32"
                  ></ellipse>
                  <g
                    id="g645"
                    fill="#fff"
                    fillOpacity="1"
                    strokeWidth="0.224"
                    transform="matrix(1.01478 -.45871 .56093 .82985 -949.473 -423.558)"
                  >
                    <rect
                      id="rect10241-0"
                      width="61"
                      height="4.88"
                      x="-433.097"
                      y="673.436"
                      ry="0"
                    ></rect>
                    <rect
                      id="rect10241-7"
                      width="61"
                      height="4.88"
                      x="-428.217"
                      y="658.796"
                      ry="0"
                    ></rect>
                    <rect
                      id="rect10241-79"
                      width="61"
                      height="4.88"
                      x="-418.457"
                      y="644.156"
                      ry="0"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
            {/* <use href="#socks" /> */}
          </g>
          {/* SOES END */}

          <path
            id="pants"
            display={colorPants === "none" ? "none" : "block"}
            fill={colorPants}
            fillOpacity="1"
            strokeWidth="0.704"
            d="M544.067 1025.566c-233.637-40.856-40.734 65.799-157.563-340.426-61.325 332.837 31.892 333.792-176.399 321.922 34.642-313.354 57.141-383.47 91.466-490.115l195.912 2.433c109.03 380.84 46.584 506.186 46.584 506.186z"
          ></path>
          <g id="g5728" fill="#ffb380" fillOpacity="1">
            <path
              id="rect4500"
              strokeWidth="0.921"
              d="M157.14 604.647l84.92-275.289c3.6-52.185 40.933-81.121 83.383-64.63L192.817 694.665 96.665 674.45s51.563-40.915 60.475-69.803z"
            ></path>
            <path
              id="rect4500-3"
              strokeWidth="0.894"
              d="M628.78 674.45L524.087 319.92c-6.242-54.34-43.94-78.106-84.202-53.082l121.982 389.328c4.36 13.915 138.312 51.13 66.913 18.285z"
            ></path>
          </g>
          <rect
            id="shirt"
            width="242.87"
            height="341.423"
            x="267.122"
            y="248.147"
            fill="#c8b7b7"
            fillOpacity="1"
            strokeWidth="1.035"
            ry="76.054"
          ></rect>
          <g id="shirt-container"></g>

          
          <g
            id="top-shirt"
            display={colorShirt === "none" ? "none" : "block"}
            fill={colorShirt}
            fillOpacity="0.988"
          >
            <rect
              id="shirt-0"
              width="240.951"
              height="352.995"
              x="268.606"
              y="297.559"
              strokeWidth="1.048"
              ry="78.631"
            ></rect>
            <path
              id="rect4500-7"
              strokeWidth="1.024"
              d="M192.768 413.925l5.635-71.201c-5.744-55.063 71.066-108.272 122.999-98.733-12.99 66.172 31.97 71.179-21.558 216.9l-87.973-4.318c-33.065-1.622-21.626-10.759-19.103-42.648z"
            ></path>
            <path
              id="rect4500-3-4"
              strokeWidth="1.02"
              d="M577.806 414.342l-3.051-64.938c4.95-66.358-77.49-120.712-125.093-98.984l27.222 198.767 90.305.407c25.184.114 11.888-8.22 10.617-35.252z"
            ></path>
          </g>
          <path
            id="path4420"
            fill="#ffb380"
            fillOpacity="1"
            strokeWidth="0.572"
            d="M460.934 268.212a72.434 79.185 0 01-73.315 76.597 72.434 79.185 0 01-71.513-78.606 72.434 79.185 0 0170.465-79.73 72.434 79.185 0 0174.322 75.43"
          ></path>
          <g id="g4362" transform="matrix(1 0 0 1.00776 18.367 -78.446)">
            <g id="g937" transform="matrix(1.0924 0 0 1.4678 1525.55 273.906)">
              <g id="g1072">
                <rect
                  id="rect111"
                  width="195.014"
                  height="290.143"
                  x="214.04"
                  y="128.424"
                  fill="#ffb380"
                  strokeWidth="0.485"
                  ry="114.47"
                  transform="matrix(.91542 0 0 .6813 -1344.274 -225.497)"
                ></rect>
                <g
                  id="g4498"
                  fill="#ffb380"
                  transform="matrix(.91542 0 0 .6813 -1344.274 -225.497)"
                >
                  <rect
                    id="rect451"
                    width="33.295"
                    height="52.321"
                    x="199.77"
                    y="233.066"
                    ry="16.648"
                  ></rect>
                  <rect
                    id="rect451-8"
                    width="33.295"
                    height="52.321"
                    x="387.65"
                    y="230.687"
                    ry="16.648"
                  ></rect>
                </g>
                <g
                  id="g947"
                  transform="matrix(.05721 0 0 .06452 -769.798 -11.29)"
                >
                  <g id="g941">
                    <path
                      id="path808"
                      fill="#280b0b"
                      strokeWidth="0.99"
                      d="M-4104.807 197.392A875.185 701.575 0 01-4542.4 804.974a875.185 701.575 0 01-875.185 0 875.185 701.575 0 01-437.592-607.582"
                    ></path>
                  </g>
                  <path
                    id="path866"
                    fill="red"
                    strokeWidth="1.153"
                    d="M-4575.871 783.933a413.832 118.911 0 01-418.863 115.024 413.832 118.911 0 01-408.57-118.04 413.832 118.911 0 01402.582-119.728 413.832 118.911 0 01424.612 113.27"
                  ></path>
                  <path
                    id="rect700"
                    fill="#ececec"
                    strokeWidth="8.451"
                    d="M-5694.787 195.772h1457.89a21.017 21.017 0 0121.064 21.063v70.374c0 7.666.216 20.83-4.39 26.783-6.274 8.108-557.616 8.16-1224.904 8.16-137.89 0-270.723-4.611-270.723-21.063v-84.254a21.017 21.017 0 0121.063-21.063z"
                  ></path>
                </g>
              </g>
            </g>
            <g id="eyes">
              <g
                id="g1360"
                transform="matrix(.08553 0 0 .11538 850.37 227.943)"
              >
                <path
                  id="path1250"
                  fill="#2b1100"
                  fillOpacity="1"
                  d="M-4770.709-161.719a361.49 118.911 0 01-361.144 118.911 361.49 118.911 0 01-361.834-118.684 361.49 118.911 0 01360.454-119.137 361.49 118.911 0 01362.522 118.456l-361.487.454z"
                ></path>
                <path
                  id="eye-inner-right"
                  fill={eyesColor}
                  strokeWidth="1.075"
                  d="M-5018.043-161.719a104.642 123.667 0 01-92.296 122.804 104.642 123.667 0 01-114.074-93.826 104.642 123.667 0 0165.377-144.943 104.642 123.667 0 01129.501 59.622l-93.15 56.343z"
                  display={false ? "none" : "block"}
                ></path>
              </g>
              <g
                id="g1360-7"
                transform="matrix(.08553 0 0 .11538 767.132 227.943)"
              >
                <path
                  id="path1250-0"
                  fill="#2b1100"
                  d="M-4770.709-161.719a361.49 118.911 0 01-361.144 118.911 361.49 118.911 0 01-361.834-118.684 361.49 118.911 0 01360.454-119.137 361.49 118.911 0 01362.522 118.456l-361.487.454z"
                ></path>
                <path
                  id="eye-inner-left"
                  fill={eyesColor}
                  strokeWidth="1.075"
                  d="M-5018.043-161.719a104.642 123.667 0 01-92.296 122.804 104.642 123.667 0 01-114.074-93.826 104.642 123.667 0 0165.377-144.943 104.642 123.667 0 01129.501 59.622l-93.15 56.343z"
                  display={isSleeping ? "none" : "block"}
                ></path>
              </g>
            </g>
            <g id="g4339" fill="#520" fillOpacity="1">
              <path
                id="rect4160"
                d="M377.907 71.618h118.227a89.743 89.743 144.162 01-102.888 74.308H281.683a85.967 85.967 142.323 0196.224-74.308z"
              ></path>
              <path
                id="rect4160-8"
                d="M676.146-547.256h5.289a71.78 71.78 179.794 01-101.513.366 68.041 68.041 179.782 0196.224-.366z"
                transform="matrix(.00005 1 -.51822 .85525 0 0)"
              ></path>
              <path
                id="rect4160-8-9"
                d="M689.142-676.63h5.288a71.78 71.78 179.757 01-101.512.43 68.042 68.042 179.744 0196.224-.43z"
                transform="matrix(.00008 1 -.68661 .72702 0 0)"
              ></path>
            </g>
          </g>
          <ellipse
            id="path4541"
            cx="395.095"
            cy="175.064"
            fill="#f95"
            fillOpacity="0.997"
            strokeWidth="1.405"
            rx="15.378"
            ry="8.028"
          ></ellipse>
        </g>
      </g>
      <g
        id="tooth-brush"
        fillOpacity="1"
        stroke="#000"
        paintOrder="fill markers stroke"
        transform="matrix(2.17835 0 0 2.08642 -510.815 -160.23)"
        display={colorBrush}
      >
        <rect
          id="rect20874"
          width="118.867"
          height="9.831"
          x="388.776"
          y="192.154"
          fill="#2b0000"
          strokeWidth="0.247"
          ry="4.916"
        ></rect>
        <rect
          id="rect20928"
          width="35.75"
          height="12.512"
          x="397.714"
          y="180.535"
          fill="#fff"
          strokeDasharray="none"
          strokeWidth="0.647"
          ry="4.916"
        ></rect>
        <rect
          id="rect20933"
          width="3.575"
          height="9.831"
          x="483.513"
          y="192.154"
          fill="#fff"
          strokeWidth="0.247"
          ry="1.787"
        ></rect>
        <rect
          id="rect20933-4"
          width="3.575"
          height="9.831"
          x="490.663"
          y="192.21"
          fill="#fff"
          strokeWidth="0.247"
          ry="1.787"
        ></rect>
        <path
          id="path21013"
          fill="#fff"
          strokeWidth="0.198"
          d="M405.757 180.037v8.043z"
        ></path>
        <path
          id="path21013-6"
          fill="#fff"
          strokeWidth="0.198"
          d="M412.088 179.713v8.044z"
        ></path>
        <path
          id="path21013-0"
          fill="#fff"
          strokeWidth="0.198"
          d="M408.697 179.83v8.043z"
        ></path>
        <path
          id="path21013-7"
          fill="#fff"
          strokeWidth="0.198"
          d="M403.43 179.98v8.043z"
        ></path>
        <path
          id="path21013-2"
          fill="#fff"
          strokeWidth="0.198"
          d="M414.66 180.129v8.044z"
        ></path>
        <path
          id="path21013-9"
          fill="#fff"
          strokeWidth="0.198"
          d="M419.967 180.083v8.044z"
        ></path>
        <path
          id="path21013-6-8"
          fill="#fff"
          strokeWidth="0.198"
          d="M426.223 180.208v8.044z"
        ></path>
        <path
          id="path21013-0-1"
          fill="#fff"
          strokeWidth="0.198"
          d="M422.906 180.1v8.044z"
        ></path>
        <path
          id="path21013-7-1"
          fill="#fff"
          strokeWidth="0.198"
          d="M417.34 180.1v8.044z"
        ></path>
        <path
          id="path21013-2-6"
          fill="#fff"
          strokeWidth="0.198"
          d="M428.719 180.325v8.043z"
        ></path>
      </g>
      <g
        id="stars"
        fill="#fff"
        fillOpacity="1"
        stroke="#fffffc"
        strokeDasharray="none"
        strokeOpacity="1"
        strokeWidth="1.002"
        paintOrder="fill markers stroke"
        display={isTidyUp === "block" ? "block" : "none"}
      >
        <path
          id="path21608"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          opacity="1"
          transform="matrix(1.05045 0 0 1.09668 -32.94 -36.33)"
        ></path>
        <path
          id="path21608-6"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 483.756 250.695)"
        ></path>
        <path
          id="path21608-8"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 -81.405 181.458)"
        ></path>
        <path
          id="path21608-5"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 -98.58 276.493)"
        ></path>
        <path
          id="path21608-4"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 64.455 96.881)"
        ></path>
        <path
          id="path21608-8-9"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 473.982 -75.167)"
        ></path>
        <path
          id="path21608-5-6"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 -1.185 409.706)"
        ></path>
        <path
          id="path22205"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          opacity="1"
          transform="matrix(1.05045 0 0 1.09668 -32.94 -36.33)"
        ></path>
        <path
          id="path22205-6"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 108.701 25.672)"
        ></path>
        <path
          id="path21608-6-7-67"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 413.707 -39.065)"
        ></path>
        <path
          id="path22205-0"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 386.91 -170.395)"
        ></path>
        <path
          id="path22205-1"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 471.5 -143.9)"
        ></path>
        <path
          id="path22205-8"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 356.7 -355.851)"
        ></path>
        <path
          id="path22205-4"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 301.113 -242.306)"
        ></path>
        <path
          id="path22205-7"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 -96.458 -89.651)"
        ></path>
        <path
          id="path21608-6-7-9"
          d="M176.584 197.84l17.393-14.764-15.838 15.893 19.415 11.98-20.01-10.152-5.393 22.167 3.472-22.167-22.749 1.72 22.155-3.548-8.666-21.104z"
          transform="matrix(1.05045 0 0 1.09668 17.345 -104.669)"
        ></path>
        <path
          id="path22205-845"
          d="M248.921 417.904l-20.988 1.851 6.569 20.02-8.247-19.39-17.01 12.435 15.892-13.834-17.081-12.336 18.068 10.84 6.453-20.058-4.726 20.533z"
          transform="matrix(1.05045 0 0 1.09668 -38.454 -344.497)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
