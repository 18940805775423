import React, { useEffect, useRef } from "react";
import "./ButtonsPanel.css";
import Button from "../button/Button";
import ButtonWhat from "../button/buttonWhat/ButtonWhat";

const ButtonsPanel = ({
  isSleeping,
  setIsSleeping,
  setEyesColor,
  setColorShoes,
  setColorPants,
  setColorShirt,
  setUnderwearColor,
  setColorSocks,
  colorSocks,
  setColorBanana,
  setColorCereal,
  setColorBrush,
  colorBrush,
  isTidyUp,
  setIsTidyUp,
  setCurrentAction,
  setIsIn,
  isIn,
}) => {
  let layer1 = document.querySelector("#g5724");
  useEffect(() => {}, []);

  useRef(() => {
    layer1 = document.querySelector("#g5724");
  }, []);

  const btns_wear = [
    {
      item: "shoes",
      setColor: setColorShoes,
      type: "wear",
      setCurrentAction: setCurrentAction,
    },
    {
      item: "pants",
      setColor: setColorPants,
      type: "wear",
      setUnderwearColor: setUnderwearColor,
      setCurrentAction: setCurrentAction,
    },
    {
      item: "shirt",
      type: "wear",
      setColor: setColorShirt,
      setCurrentAction: setCurrentAction,
    },
    {
      item: "socks",
      type: "wear",
      setColor: setColorSocks,
      setCurrentAction: setCurrentAction,
    },
  ];

  const btns_eat = [
    {
      item: "cereal",
      type: "eat",
      setColor: setColorCereal,
      setCurrentAction: setCurrentAction,
    },
    {
      item: "banana",
      type: "eat",
      setColor: setColorBanana,
      setCurrentAction: setCurrentAction,
    },
  ];

  const handleWakeUp = (isSleeping, setIsSleeping, setEyesColor) => {
    console.log("Wake Up");
    if (isSleeping) {
      setEyesColor("#ffb380");
      setCurrentAction("wake Up");
    } else {
      setCurrentAction("am sleeping");
      setEyesColor("black");
      setIsSleeping(!isSleeping);
    }
    // setCurrentAction("none");
    setIsSleeping(!isSleeping);
  };

  const handleTidyUp = () => {
    setCurrentAction("Tidy Up");

    if (isTidyUp === "block") {
      setIsTidyUp("none");
    } else {
      setIsTidyUp("block");
      setTimeout(() => {
        setIsTidyUp("none");
      }, 5000);
    }
  };

  const handleGoOut = () => {
    if (isIn) {
      console.log("Go Out");
      layer1?.parentElement?.removeChild(layer1);
      layer1?.setAttribute("display", "none");
      setCurrentAction("go out");
    }
    setIsIn(!isIn);
  };

  const mapButtons = (buttons) => {
    return buttons?.map((btn, i) => {
      return (
        <ButtonWhat
          colorSocks={colorSocks}
          key={i + btn.item}
          setColor={btn.setColor}
          item={btn.item}
          setUnderwearColor={btn.setUnderwearColor || ""}
          setCurrentAction={btn.setCurrentAction}
        ></ButtonWhat>
      );
    });
  };
  const handleBrushTeeth = () => {
    setCurrentAction("brush teeth");
    if (colorBrush === "block") {
      setColorBrush("none");
    } else if (colorBrush === "none") {
      setColorBrush("block");
      setTimeout(() => {
        setColorBrush("none");
      }, 5000);
    }
  };

  return (
    <div className="buttons-panel">
      <Button
        id="btn-sleep"
        onClick={() => handleWakeUp(isSleeping, setIsSleeping, setEyesColor)}
        buttonName={isSleeping ? "Wake Up" : "Back to Sleep"}
        isHeader={false}
      ></Button>
      <Button buttonName={"Wear"} isHeader={true}></Button>
      {mapButtons(btns_wear)}
      <Button buttonName={"Eat"} isHeader={true}></Button>
      {mapButtons(btns_eat)}
      <Button
        id="btn-brush-teeth"
        onClick={handleBrushTeeth}
        buttonName={"brush teeth"}
        isHeader={false}
      ></Button>
      <Button
        id="btn-tidy-up"
        onClick={handleTidyUp}
        buttonName={"tidy up"}
        isHeader={false}
      ></Button>
      <Button
        id="btn-go-out"
        onClick={handleGoOut}
        buttonName={"go out"}
        isHeader={false}
      ></Button>
    </div>
  );
};

export default ButtonsPanel;
