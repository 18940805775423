import React from "react";
import "./TextBox.css";

const TextBox = ({ action }) => {
  return (
    <section id="text-box" className="text-box">
      {action !== "none" && `Now I ${action}`}
    </section>
  );
};

export default TextBox;
