import React from "react";
import "./Button.css";


const Button = ({ buttonName, onClick,isHeader ,id}) => {
  return (
    <>
      <button id={id} className={isHeader? "button btn-header": "button"} onClick={onClick}>
        {buttonName}
      </button>
    </>
  );
};

export default Button;
