import React, { useState } from "react";

const ButtonWhat = ({
  item,
  setColor,
  setUnderwearColor,
  setCurrentAction,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const svg = document.querySelector("svg");
  const shirtContainer =document.querySelector("#shirt-container");
  const shoesAndSocks = document.querySelector("#g5724");

  const elShoes = document.getElementById("shoes");
  const elSocks = document.getElementById("socks");
  const elPants = document.getElementById("pants");
  const elBanana = document.getElementById("banana");
  const elCereal = document.getElementById("cereal");
  const elShirt = document.getElementById("top-shirt");

  const handleClick = (e) => {
    console.log(e.target.innerText, item);
    if (!isClicked) {
      if (item === "pants") {
        setCurrentAction("wear pants");
        setColor("#008080");
        setUnderwearColor("none");
        shoesAndSocks?.append(elPants);
        shirtContainer?.append(elPants);
        // svg.children[0].append(shoesAndSocks);
        return;

      } else if (item === "shirt") {
        setCurrentAction("wear shirt");
        setColor("#d35f5f");
        shirtContainer?.appendChild(elShirt);
        
        return;

      } else if (item === "socks") {
        setCurrentAction("wear socks");
        setColor("1");
        // shoesAndSocks?.appendChild(elSocks);
        // shoesAndSocks?.appendChild(elSocks);
        shirtContainer?.appendChild(elSocks);

        return;
      } else if (item === "shoes") {
        setCurrentAction("wear shoes");
        setColor("1");
        shirtContainer?.appendChild(elShoes);
        // shoesAndSocks?.appendChild(elShoes);
        // shirtContainer?.appendChild(shoesAndSocks)
        // svg?.appendChild(shoesAndSocks);
        return;
      } else if (item === "banana") {
        setCurrentAction("eat banana");
        console.log("banana clicked", elBanana);
        setColor("#ffdb22");
        svg?.appendChild(elBanana);
        setTimeout(() => {
          setColor("none");
        }, 5000);
        return;
      } else if (item === "cereal") {
        setCurrentAction("eat cereal");
        console.log("cereal clicked");
        setColor("#005ca1");
        console.log("cereal", elCereal);
        svg?.appendChild(elCereal);
        setTimeout(() => {
          setColor("none");
        }, 5000);
        return;
      }
    } else {
      if (item === "pants") {
        setColor("none");
        setUnderwearColor("#ac9393");
      }
      else if (item === "socks") {
        setColor("0");
      }
      else if (item === "shirt") {
        setColor("0");
      }
      else if (item === "shoes") {
        setColor("0");
      }
      else if (item === "banana") {
        console.log("banana clicked off");
        setColor("none");
      }
      else if (item === "cereal") {
        console.log("cereal clicked off");
        setColor("none");
      }
    }
    setCurrentAction("none");
    setIsClicked(!isClicked);
  };
  return (
    <button id={`btn-${item}`} className="btn-what" onClick={handleClick}>
      {item}
    </button>
  );
};

export default ButtonWhat;