import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <img
        src="https://docs.provengo.tech/images/provengo.png"
        alt="logo"
        className="header-item logo"
      />
      <h1 className="header-item title">Morning Routine</h1>
    </header>
  );
};

export default Header;
