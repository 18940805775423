import "./App.css";
import "./images/SVG.css";
import ButtonsPanel from "./components/buttonsPanel/ButtonsPanel";
import Header from "./components/header/Header";
import CenterPanel from "./components/centerPanel/CenterPanel";
import {useState } from "react";
import TextBox from "./components/textBox/TextBox";

function App() {
  const [colorPants, setColorPants] = useState("none");
  const [colorShirt, setColorShirt] = useState("none");
  const [colorSocks, setColorSocks] = useState("0");
  const [colorShoes, setColorShoes] = useState("0");
  const [underwearColor, setUnderwearColor] = useState("#ac9393");
  const [eyesColor, setEyesColor] = useState("black");
  const [colorBanana, setColorBanana] = useState("none");
  const [colorCereal, setColorCereal] = useState("none");
  const [isSleeping, setIsSleeping] = useState(true);
  const [colorBrush, setColorBrush] = useState("none");
  const [isTidyUp, setIsTidyUp] = useState("none");
  const [currentAction, setCurrentAction] = useState("none");
  const [input, setInput] = useState("");
  const [isIn, setIsIn] = useState(true);
  const [newValues, setNewValues] = useState([]);

  // useEffect(() => {}, [
  //   colorPants,
  //   colorShirt,
  //   colorShoes,
  //   colorSocks,
  //   eyesColor,
  //   colorBanana,
  //   isIn,
  // ]);

  return (
    <div className="App">
      <Header></Header>
      <ButtonsPanel
        isSleeping={isSleeping}
        setIsSleeping={setIsSleeping}
        setEyesColor={setEyesColor}
        setColorShoes={setColorShoes}
        setColorSocks={setColorSocks}
        setUnderwearColor={setUnderwearColor}
        setColorPants={setColorPants}
        setColorShirt={setColorShirt}
        setColorBanana={setColorBanana}
        setColorCereal={setColorCereal}
        setColorBrush={setColorBrush}
        colorBrush={colorBrush}
        isTidyUp={isTidyUp}
        setIsTidyUp={setIsTidyUp}
        setCurrentAction={setCurrentAction}
        currentAction={currentAction}
        setIsIn={setIsIn}
        isIn={isIn}
      ></ButtonsPanel>
      <CenterPanel
        colorCereal={colorCereal}
        isSleeping={isSleeping}
        eyesColor={eyesColor}
        colorShoes={colorShoes}
        colorSocks={colorSocks}
        underwearColor={underwearColor}
        colorShirt={colorShirt}
        colorPants={colorPants}
        colorBanana={colorBanana}
        colorBrush={colorBrush}
        isTidyUp={isTidyUp}
        input={input}
        setInput={setInput}
        newValues={newValues}
        setNewValues={setNewValues}
        setCurrentAction={setCurrentAction}
      ></CenterPanel>
      <TextBox action={currentAction}></TextBox>
    </div>
  );
}

export default App;
